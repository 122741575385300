export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        blogs :"",
        blog : '', 
    },
    
    getters : {
        blogs: (state )=> state.blogs,
		blog: (state )=> state.blog,
    },


actions : {

	async getblogs( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'getblogs')
        return new Promise((resolve, reject) => {
		axios.get("web/blogs",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setblogs',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},




},

mutations : {
	setblogs: (state, blogs) => (state.blogs = blogs),
	setblog :(state,blog ) => (state.blog = blog), 

},
}