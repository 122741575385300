<template>
  <router-view/>
</template>
<script>
export default {


  mounted(){
      var scripts = [

        "/assets/js/vendor/jquery-3.1.0.min.js",
        "/assets/js/vendor/jquery.tooltipster.min.js",
        "/assets/js/side-menu.js",
        "/assets/js/tooltip.js",
        "/assets/js/user-board.js",
        "/assets/js/home-v2.js",
        "/assets/js/footer.js"

    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    })
  },
  
}
</script>


