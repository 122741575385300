let routes = [
    {
        path: "/website_admin",
        name: "website_admin",
        props:true,
        component: () => import("@/views/auth/login.vue"),
        meta: {
        title: 'Login and Start Editing Your Website Like a Pro'
        }

    },
]


export default routes;