//import router from '@/router'
// import store from '@/store'

let routes = [
    { 
        path: "/",
        props: true,
        component: () => import("@/views/dash.vue"),
        title: 'Improving the Quality of Science Education  through Africa',
          children: [

            {
              path: "/",
              name: "welcome",
              props:true,
              component: () => import("@/views/website/home/welcome.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              }
            },

            {
              path: "/whatsapp",
              name: "whatsapp_chat_page",
              props:true,
              component: () => import("@/views/website/home/whatsapp_chat.vue"),
              meta: {
              title: 'Science Teachers Whatapp Groups'
              },

              children:[
                {
                  path: "primary",
                  name: "whatsapp_primary",
                  props:true,
                  component: () => import("@/views/website/home/whatsapp/whatsapp_primary.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "secondary",
                  name: "whatsapp_secondary",
                  props:true,
                  component: () => import("@/views/website/home/whatsapp/whatsapp_secondary.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },
              ]
            },


            {
              path: "/who-we-are",
              props:true,
              component: () => import("@/views/website/essentials/main.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              },

              children:[

                {
                  path: "",
                  name: "whoweare",
                  props:true,
                  component: () => import("@/views/website/essentials/whoweare.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "about-sti",
                  name: "aboutus",
                  props:true,
                  component: () => import("@/views/website/essentials/about.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "our-partners",
                  name: "ourpartners",
                  props:true,
                  component: () => import("@/views/website/essentials/partners.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "accountability",
                  name: "accountability",
                  props:true,
                  component: () => import("@/views/website/essentials/accountability.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "commitement-to-safeguarding",
                  name: "safeguarding",
                  props:true,
                  component: () => import("@/views/website/essentials/safeguarding.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "sti-core-values",
                  name: "sti_core_values",
                  props:true,
                  component: () => import("@/views/website/essentials/sti_core_values.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "commitment-to-quality-education",
                  name: "commitment_to_quality_education",
                  props:true,
                  component: () => import("@/views/website/essentials/commitment_to_quality_education.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

              ]
            },

            //what we do


            {
              path: "/what-we-do",
              props:true,
              component: () => import("@/views/website/whatwedo/main.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              },

              children:[

                {
                  path: "",
                  name: "what_we_do",
                  props:true,
                  component: () => import("@/views/website/whatwedo/what_we_do.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },


                {
                  path: "promoting-competency-based-education",
                  name: "competence_based_training",
                  props:true,
                  component: () => import("@/views/website/whatwedo/competence_based_training.vue"),
                  meta: {
                  title: 'Promoting Competency-Based Education(CBE)'
                  }
                },


                {
                  path: "cpd_teacher_support_program",
                  name: "cpd_teacher_support_program",
                  props:true,
                  component: () => import("@/views/website/whatwedo/cpd_teacher_support_program.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "heath_and_safety_message",
                  name: "heath_and_safety_message",
                  props:true,
                  component: () => import("@/views/website/whatwedo/heath_and_safety_message.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },


                {
                  path: "intergration_of_ict",
                  name: "intergration_of_ict",
                  props:true,
                  component: () => import("@/views/website/whatwedo/intergration_of_ict.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },


                {
                  path: "literacy_and_numeracy",
                  name: "literacy_and_numeracy",
                  props:true,
                  component: () => import("@/views/website/whatwedo/literacy_and_numeracy.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "mini_labaratory",
                  name: "mini_labaratory",
                  props:true,
                  component: () => import("@/views/website/whatwedo/mini_labaratory.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "youth_skilling_program",
                  name: "youth_skilling_program",
                  props:true,
                  component: () => import("@/views/website/whatwedo/youth_skilling_program.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

              ]
            },


            {
              path: "/south-sudan",
              props:true,
              component: () => import("@/views/website/south_sudan/main.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              },

              children:[

                {
                  path: "",
                  name: "sti_south_sudan",
                  props:true,
                  component: () => import("@/views/website/south_sudan/sti_south_sudan.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },


                {
                  path: "about",
                  name: "about_sti_south_sudan",
                  props:true,
                  component: () => import("@/views/website/south_sudan/about.vue"),
                  meta: {
                  title: 'Promoting Competency-Based Education(CBE)'
                  }
                },


                {
                  path: "policies-and-briefs",
                  name: "sti_ss_policies_and_briefs",
                  props:true,
                  component: () => import("@/views/website/south_sudan/policies_and_briefs.vue"),
                  meta: {
                  title: 'Promoting Competency-Based Education(CBE)'
                  }
                },

                {
                  path: "jobs-&-tenders",
                  name: "sti_ss_oppotunities",
                  props:true,
                  component: () => import("@/views/website/south_sudan/oppotunities.vue"),
                  meta: {
                  title: 'Promoting Competency-Based Education(CBE)'
                  }
                },

                {
                  path: "contact-us",
                  name: "sti_ss_contact_us",
                  props:true,
                  component: () => import("@/views/website/south_sudan/contact_us.vue"),
                  meta: {
                  title: 'Promoting Competency-Based Education(CBE)'
                  }
                },

              ]
            },


            


            
          


            {
              path: "/contact-us",
              name: "contactus",
              props:true,
              component: () => import("@/views/website/essentials/contact.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              }
            },


            {
              path: "/gallery",
              name: "gallery",
              props:true,
              component: () => import("@/views/website/essentials/gallery.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              }
            },





            //services

            {
              path: "/whatwedo",
              name: "whatwedo",
              props:true,
              component: () => import("@/views/website/services/services.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              }
            },


            {
              path: "/whatwedo/competency-based-education",
              name: "what_we_do_cbe",
              props:true,
              component: () => import("@/views/website/services/what_we_do_cbe.vue"),
              meta: {
              title: 'Improving the Quality of Science Education  through Africa'
              }
            },



              //news

              {
                path: "/newsroom",
                name: "newsroom",
                props:true,
                component: () => import("@/views/website/newsroom/newsroom.vue"),
                meta: {
                title: 'Improving the Quality of Science Education  through Africa'
                }
              },
  
  
              {
                path: "/newsroom/:id",
                name: "newsroomdetails",
                props:true,
                component: () => import("@/views/website/newsroom/newsroomdetails.vue"),
                meta: {
                title: 'Improving the Quality of Science Education  through Africa'
                }
              },



                 //community

                {
                  path: "/careers",
                  name: "careers",
                  props:true,
                  component: () => import("@/views/website/community/careers.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },


                {
                  path: "/donate",
                  name: "donate",
                  props:true,
                  component: () => import("@/views/website/community/donate.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },


                {
                  path: "/events",
                  name: "events",
                  props:true,
                  component: () => import("@/views/website/community/events.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },


                {
                  path: "/volunteer",
                  name: "volunteer",
                  props:true,
                  component: () => import("@/views/website/community/volunteer.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },

                {
                  path: "/research-and-reports",
                  name: "researchandreports",
                  props:true,
                  component: () => import("@/views/website/community/reports.vue"),
                  meta: {
                  title: 'Improving the Quality of Science Education  through Africa'
                  }
                },


                // {
                //   path: "/STI-South-Sudan",
                //   props:true,
                //   component: () => import("@/views/website/south_sudan/main.vue"),
                //   title: '',

                //   children:[

                //     {
                //       path: "",
                //       name: "sti_south_sudan",
                //       props:true,
                //       component: () => import("@/views/website/south_sudan/sti_south_sudan"),
                //       title: '',
    
                //     },

                //     {
                //       path: "about",
                //       name: "about_sti_south_sudan",
                //       props:true,
                //       component: () => import("@/views/website/south_sudan/sti_south_sudan"),
                //       title: '',
    
                //     },

                //   ]

                // },

          ]
        },

    ]

export default routes;