import { createRouter, createWebHistory } from 'vue-router'
import main_routes from '@/router/approutes/openroutes'
import auth_routes from '@/router/approutes/authroutes'
const baseRoutes = []
const routes = baseRoutes.concat(auth_routes, main_routes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(`Navigating to: ${to.name}`);
  next();
});

// router.beforeEach((to, from, next) => {
//   const nearestWithTitle =to.matched.slice().reverse().find(r => r.meta && r.meta.title)
//   const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
//  let  VUE_APP_TITLE = "African Illustrator"
//   let fulltitle = nearestWithTitle.meta.title + ' - ' + VUE_APP_TITLE 
//   let paramstitle =  to.params.title + ' - ' + VUE_APP_TITLE 

//   if(to.params.title){
//     document.title = paramstitle
//   }else{
//     document.title = fulltitle 
//   }
  
//   Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))
//   if(!nearestWithMeta) return next()
//   nearestWithMeta.meta.metaTags.map(tagDef => {
//     const tag = document.createElement('meta')

//     Object.keys(tagDef).forEach(key => {
//       tag.setAttribute(key, tagDef[key])
//     })

//     tag.setAttribute('data-vue-router-controlled', '')

//     return tag
//   })
//   .forEach(tag => document.head.appendChild(tag))
 
//   next()
// })

export default router
