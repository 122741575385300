export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        banners :"",
        banner : '', 
    },
    
    getters : {
        banners: (state )=> state.banners,
		banner: (state )=> state.banner,
    },


actions : {

	async getbanners( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savebanner')
        return new Promise((resolve, reject) => {
		axios.get("web/banners",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setbanners',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},




},

mutations : {
	setbanners: (state, banners) => (state.banners = banners),
	setbanner :(state,banner ) => (state.banner = banner), 

},
}