export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        editType:'html',
    },
    
    getters : {
        editType: state => state.editType,
    },

    mutations : {
        seteditType :(state,editType ) => (state.editType = editType),
    
    },


    actions : {

        async change_user_edit_type( { commit }, value) {
            commit('seteditType', value)     
        },




    },


}