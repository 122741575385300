export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        galleries :"",
        gallery : '', 
    },
    
    getters : {
        galleries: (state )=> state.galleries,
		gallery: (state )=> state.gallery,
    },


actions : {

	async getgalleries( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'getgalleries')
        return new Promise((resolve, reject) => {
		axios.get("web/galleries",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setgalleries',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},




},

mutations : {
	setgalleries: (state, galleries) => (state.galleries = galleries),
	setgallery :(state,gallery ) => (state.gallery = gallery), 

},
}