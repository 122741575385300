import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const ls = new SecureLs({isCompression:false});

import auth  from './modules/auth'
import utils  from './modules/utils'
import websetting  from './modules/websetting.js'
import banners  from './modules/banners'
import whatwedo  from './modules/whatwedo'
import gallery  from './modules/gallery'
import blog  from './modules/blogs'
import clients  from './modules/clients'
import webpages  from './modules/webpages'
export default createStore({
  modules: {
    auth,
    banners,
    websetting,
    whatwedo,
    gallery,
    blog,
    clients,
    webpages,
    utils

    
  },
    
  plugins: [createPersistedState(
    {
      key : 'www.scienceteacherinitiative.org',
      paths: ['websetting','auth','utils'],
      storage:{
         getItem : (key) => ls.get(key),
         setItem: (key, value) => ls.set(key, value),
         removeItem: (key) => ls.remove(key)
      }
    }
  )],
  
})
