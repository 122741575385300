export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        websetting :"",
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloader:'',
    },
    
    getters : {
        websetting: (state )=> state.websetting,
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloader: (state )=> state.pageloader,
    },


actions : {

	async getwebsetting( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'savebanner')
        return new Promise((resolve, reject) => {
		axios.get("web/settings",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setwebsetting',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},




},

mutations : {
	setwebsetting: (state, websetting) => (state.websetting = websetting),
    setloader :(state,loading ) => (state.loading = loading), 
	setpageloader :(state,pageloader ) => (state.pageloader = pageloader), 
	seterror :(state,error ) => (state.error = error), 
	seterrors :(state,errors ) => (state.errors = errors), 
	setmsg :(state,msg ) => (state.msg = msg), 
	setapierror :(state,apierror ) => (state.apierror = apierror),

},
}