export const namespaced = true 
import axios from 'axios'
import router from '@/router'
//import store from '@/store'

export default {
    state : {
        services :"",
        service : '', 
    },
    
    getters : {
        services: (state )=> state.services,
		service: (state )=> state.service,
    },


actions : {

	async getservices( { commit }, data) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', 'saveservice')
        return new Promise((resolve, reject) => {
		axios.get("web/whatwedo",  data)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setservices',response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},


    async getservice( { commit }, id) {
		commit('seterror', '')
		commit('setmsg', '')
		commit('setloader', id)
        return new Promise((resolve, reject) => {
		axios.get("web/whatwedo/" + id)
		.then(response => {	
			commit('setloader', false)
			if(response.data.res){
				commit('setservice' ,response.data.res)
			}
			resolve()
		})
		.catch(error=> {
			commit('setloader', false)
			if (error.response) {
				if(error.response.data){
					if(error.response.data.msg){
						commit('seterror', error.response.data.msg)
					}else if(error.response.data.message){
						commit('seterror', error.response.data.message)
					}
					
				}
			}
			reject()
		});
    });
		
			
	},




},

mutations : {
	setservices: (state, services) => (state.services = services),
	setservice :(state,service ) => (state.service = service), 

},
}